export const columns: any = [
	{
		label: 'Campaign',
		value: 'campaign_name',
	},
	{
		label: 'Campaign Group',
		value: 'campaign_group',
	},
	{
		label: 'Status',
		value: 'campaign_status',
	},
	{
		label: 'Type',
		value: 'campaign_type',
	},
	{
		label: 'Platform',
		value: 'platform_name',
	},
	{
		label: 'Impressions',
		value: 'impressions',
	},
	{
		label: 'Clicks',
		value: 'clicks',
	},
	{
		label: 'CTR',
		value: 'ctr',
	},
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Unique Visitors',
		value: 'unique_visitors',
	},
	{
		label: 'Form Fill',
		value: 'form_fill',
	},
	{
		label: 'Conversion Rate',
		value: 'conversion_rate',
	},
	{
		label: 'Platform Cost',
		value: 'platform_cost',
	},
	{
		label: 'Cost Per Lead',
		value: 'cost_per_lead',
	},
	{
		label: 'Leads',
		value: 'total_leads',
	},
	{
		label: 'CPM',
		value: 'cpm',
	},
	{
		label: 'MQL',
		value: 'mql',
	},
	{
		label: 'SQL',
		value: 'sql',
	},
	{
		label: 'Opportunity',
		value: 'oppty',
	},
	{
		label: 'Total Closed Won',
		value: 'total_closed_won',
	},
	{
		label: 'ACV',
		value: 'acv',
	},
];

export const defaultColumns: any = [
	{
		label: 'Campaign',
		value: 'campaign_name',
	},
	{
		label: 'Platform',
		value: 'platform_name',
	},
	{
		label: 'Platform Cost',
		value: 'platform_cost',
	},
	{
		label: 'Clicks',
		value: 'clicks',
	},
	{
		label: 'Leads',
		value: 'total_leads',
	},
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Opportunity',
		value: 'oppty',
	},
];

export const closed_won_pdf_column = [
	{ label: 'Opportunity Name', value: 'oppty' },
	{ label: 'Account Name', value: 'account_name' },
	{ label: 'Email', value: 'email' },
	{ label: 'Revenue', value: 'revenue' },
	{ label: 'Type', value: 'campaign_type' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Source', value: 'source' },
];
export const pipeline_pdf_column = [
	{ label: 'Opportunity Name', value: 'oppty' },
	{ label: 'Account Name', value: 'account_name' },
	{ label: 'Email', value: 'email' },
	{ label: 'Amount', value: 'amount' },
	{ label: 'Type', value: 'campaign_type' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Source', value: 'source' },
];

export const cmoclosedWonColumn = [
	{ label: 'Opportunity Name', value: 'oppty' },
	{ label: 'Account Name', value: 'account_name' },
	{ label: 'Email', value: 'email' },
	{ label: 'Revenue ($)', value: 'revenue' },
	{ label: 'Closed Date', value: 'closed_date' },
	{ label: 'Lead Source', value: 'lead_source' },
	{ label: 'Type', value: 'campaign_type' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Source', value: 'source' },
];

export const cmoPipelineColumn = [
	{ label: 'Account Name', value: 'account_name' },
	{ label: 'Opportunity Name', value: 'oppty' },
	{ label: 'Email', value: 'email' },
	{ label: 'Create Date', value: 'create_date' },
	{ label: 'Closed Date', value: 'closed_date' },
	{ label: 'Stage', value: 'stage' },
	{ label: 'Amount', value: 'amount' },
	{ label: 'Lead Source', value: 'lead_source' },
	{ label: 'Type', value: 'campaign_type' },
	{ label: 'Probability', value: 'probability' },
	{ label: 'Age', value: 'age' },
	{ label: 'Link Type', value: 'link_type' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Source', value: 'source' },
];

export const accountPerformanceColumns = [
	{
		value: 'email',
		label: 'Contact Email',
	},
	{
		value: 'first_name',
		label: 'First Name',
	},
	{
		value: 'last_name',
		label: 'Last Name',
	},
	{
		value: 'lead_id',
		label: 'Lead Id',
	},
	{
		value: 'lead_score',
		label: 'Score',
	},
	{
		value: 'click_email_count',
		label: 'Click Email',
	},
	{
		value: 'email_open_count',
		label: 'Open Email',
	},
	{
		value: 'click_link_count',
		label: 'Click Link',
	},
	{
		value: 'visit_webpage_count',
		label: 'Visit Webpage',
	},
	{
		value: 'form_submission_count',
		label: 'Filling the Form',
	},
];

export const accountPerformanceDefaultColumns = [
	{
		value: 'email',
		label: 'Contact Email',
	},
	{
		value: 'first_name',
		label: 'First Name',
	},
	{
		value: 'last_name',
		label: 'Last Name',
	},
	{
		value: 'lead_id',
		label: 'Lead Id',
	},
	{
		value: 'lead_score',
		label: 'Score',
	},
	{
		value: 'click_email_count',
		label: 'Click Email',
	},
	{
		value: 'email_open_count',
		label: 'Open Email',
	},
	// {
	//     "value": "click_link_count",
	//     "label": "Click Link"
	// },
	// {
	//     "value": "visit_webpage_count",
	//     "label": "Visit Webpage"
	// },
	// {
	//     "value": "form_submission_count",
	//     "label": "Filling the Form"
	// }
];
